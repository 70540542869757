<template>
  <div id="image-download-page" class="page">
    <div class="container">
      <div class="image-container">
        <img :src="resource_url" class="image" />
      </div>
      <p class="image-description">
        이미지를 <span class="bold">📱캡처</span>하여 <span class="bold">다운로드</span> 해주세요
      </p>
      <a ref="link" class="anchor"></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resource_url: this.$route.query.resource_url,
      autoDownloadable: false,
    };
  },

  computed: {
    autoDownloadable() {
      return typeof this.$refs.link?.download === 'function';
    },
  },

  mounted() {
    // this.autoDownload();
  },

  methods: {
    autoDownload() {
      this.$refs.link.download = +new Date();
      this.$refs.link.href = this.resource_url;
      setTimeout(() => {
        this.$refs.link.click();
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
#image-download-page {
  @include container('& > .container', $max-content-width);
  & > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: unit(16);

    .image-container {
      width: 100%;
      padding-bottom: 100%;

      .image {
        @include fsa;
      }

      .image-description {
        padding: unit(24) 0;
        font-size: unit(14);
        color: #a9afb3;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.anchor {
  visibility: hidden;
}
</style>
